<template>
  <CreateUpdateTemplate
    :customClass="'project-create create-form'"
    v-if="getPermission('project:create')"
  >
    <template v-slot:header-title>
      <v-skeleton-loader
        v-if="pageLoading"
        class="custom-skeleton custom-skeleton-full-width"
        type="button"
      ></v-skeleton-loader>
      <h1 v-else class="form-title d-flex">
        Project For
        <span
          class="m-0 form-title-create-link pl-2"
          :class="{
            'custom-nowrap-ellipsis': !lodash.isEmpty(customerObject),
          }"
          link
        >
          <template v-if="lodash.isEmpty(customerObject)">Client Name</template>
          <template v-else>{{ customerObject.display_name }}</template>
          <v-icon
            link
            large
            color="cyan"
            class="mx-2"
            v-if="lodash.isEmpty(customerObject) || isDuplicateMode"
            >mdi-plus-circle-outline</v-icon
          >
        </span>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="onSubmit"
        color="cyan"
      >
        Save Project
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="projectForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <div class="p-5 pt-0">
            <v-row>
              <v-col cols="7" class="pb-0">
                <v-container fluid>
                  <div>
                    <label class="font-weight-700 font-size-16 required"
                      >Project Name
                    </label>
                    <v-text-field
                      v-model.trim="projectCreate.name"
                      dense
                      filled
                      color="cyan"
                      label="Project Name"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      solo
                      flat
                      :rules="[
                        validateRules.required(
                          projectCreate.name,
                          'Project Name'
                        ),
                        validateRules.required(
                          projectCreate.name,
                          'Project Name'
                        ),
                        validateRules.minLength(
                          projectCreate.name,
                          'Project Name',
                          1
                        ),
                        validateRules.maxLength(
                          projectCreate.name,
                          'Project Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </div>
                  <div>
                    <v-textarea
                      v-model.trim="projectCreate.description"
                      auto-grow
                      dense
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      label="Description"
                      solo
                      flat
                      v-on:keypress="
                        (e) => manageLimit(e, 'project_description')
                      "
                      v-on:paste="(e) => onPaste(e, 'project_description')"
                      :rules="[
                        validateRules.minLength(
                          projectCreate.description,
                          'Description',
                          1
                        ),
                        validateRules.maxLength(
                          projectCreate.description,
                          'Description',
                          200
                        ),
                      ]"
                      row-height="20"
                    ></v-textarea>
                    <div class="text-right">
                      {{
                        projectCreate.description
                          ? projectCreate.description.length
                          : 0
                      }}/200
                    </div>
                  </div>

                  <div>
                    <v-layout class="my-1 bg-quo">
                      <v-flex md6 class="ml-2">
                        <table width="100%">
                          <tr>
                            <td class="font-weight-600">
                              <label>Billing Address</label>

                              <v-icon
                                v-if="customer && customer.id"
                                class="ml-3"
                                v-on:click="customerBillingDialog = true"
                                color="cyan"
                                small
                                >mdi-pencil</v-icon
                              >
                            </td>
                          </tr>
                          <tr>
                            <td class="py-0">
                              <!-- <div
                                v-if="billing && billing.property_name"
                                style="
                                  font-weight: 600 !important;
                                  color: black;
                                "
                              >
                                {{ billing.property_name }}
                              </div> -->
                              <label v-if="billing && billing.property_address">
                                <label
                                  v-if="billing && billing.property_address"
                                  >{{ billing.street_1 }},
                                  <br
                                    v-if="billing.street_2 || billing.unit_no"
                                  />
                                  <template v-if="billing.street_2">
                                    {{ billing.street_2 }},
                                  </template>
                                  <template v-if="billing.unit_no">
                                    {{ billing.unit_no }},
                                  </template>
                                  <br />
                                  {{ billing.country }},
                                  {{ billing.zip_code }}
                                </label>
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td class="font-weight-600">
                              <label>Contact details</label>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              billing_contact_person &&
                              billing_contact_person.display_name
                            "
                          >
                            <td class="py-0">
                              <label>{{
                                billing_contact_person.display_name
                              }}</label>
                            </td>
                          </tr>
                          <tr>
                            <td class="py-0">
                              <label
                                v-if="
                                  billing_contact_person &&
                                  billing_contact_person.primary_phone
                                "
                                >{{
                                  billing_contact_person.primary_phone
                                }}</label
                              >
                              <em v-else class="text-muted">
                                no phone number</em
                              >
                            </td>
                          </tr>
                          <tr>
                            <td class="py-0">
                              <label
                                v-if="
                                  billing_contact_person &&
                                  billing_contact_person.primary_email
                                "
                                >{{
                                  billing_contact_person.primary_email
                                }}</label
                              >
                              <em v-else class="text-muted"> no email</em>
                            </td>
                          </tr>
                        </table>
                      </v-flex>
                      <v-flex md6 class="mr-2 custom-border-right">
                        <table width="100%">
                          <tr>
                            <td class="font-weight-600">
                              <label>Site Location</label>
                              <v-icon
                                class="ml-3"
                                v-on:click="customerPropertyDialog = true"
                                color="cyan"
                                small
                                v-if="customer && customer.id"
                                >mdi-pencil</v-icon
                              >
                              <!--  <v-icon
                                class="ml-3"
                                v-on:click="routeToServiceHistory()"
                                color="cyan"
                                small
                                >mdi-history</v-icon
                              > -->
                            </td>
                          </tr>
                          <tr>
                            <td class="py-0">
                              <!-- <div
                              v-if="property && property.property_name"
                                style="
                                  font-weight: 600 !important;
                                  color: black;
                                "
                              >
                                {{ property.property_name }}
                              </div> -->
                              <label
                                v-if="property && property.property_address"
                              >
                                <label
                                  v-if="property && property.property_address"
                                  >{{ property.street_1 }},
                                  <br
                                    v-if="property.street_2 || property.unit_no"
                                  />
                                  <template v-if="property.street_2">
                                    {{ property.street_2 }},
                                  </template>
                                  <template v-if="property.unit_no">
                                    {{ property.unit_no }},
                                  </template>
                                  <br />
                                  {{ property.country }},
                                  {{ property.zip_code }}
                                </label>
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td class="font-weight-600">
                              <label>Contact details</label>
                              <v-chip
                                v-if="
                                  property_contact_person &&
                                  property_contact_person.tenant
                                "
                                small
                                color="green white--text"
                                label
                                class="ml-2 tenant-small-chip"
                                >Tenant</v-chip
                              >
                              <v-icon
                                class="ml-3"
                                v-on:click="
                                  person_type = 'property';
                                  customerPersonDialog = true;
                                "
                                color="cyan"
                                small
                                v-if="customer && customer.id"
                                >mdi-pencil</v-icon
                              >
                            </td>
                          </tr>
                          <tr
                            v-if="
                              property_contact_person &&
                              property_contact_person.display_name
                            "
                          >
                            <td class="py-0">
                              <label>{{
                                property_contact_person.display_name
                              }}</label>
                            </td>
                          </tr>
                          <tr>
                            <td class="py-0">
                              <label
                                v-if="property_contact_person.primary_phone"
                                >{{
                                  property_contact_person.primary_phone
                                }}</label
                              >
                              <em v-else class="text-muted">
                                no phone number</em
                              >
                            </td>
                          </tr>
                          <tr>
                            <td class="py-0">
                              <label
                                v-if="
                                  property_contact_person &&
                                  property_contact_person.primary_email
                                "
                                >{{
                                  property_contact_person.primary_email
                                }}</label
                              >
                              <em v-else class="text-muted"> no email</em>
                            </td>
                          </tr>
                        </table>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-container>
              </v-col>
              <v-col cols="5" class="pb-0">
                <v-container fluid>
                  <table class="width-100">
                    <tr>
                      <td colspan="3" class="font-weight-700 font-size-16 pb-0">
                        Project details
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0 pt-0" width="200">
                        Project number
                      </td>
                      <td
                        class="font-weight-700 font-size-16 pb-0 pt-0"
                        width="150"
                        :colspan="isUpdateMode ? 2 : 1"
                      >
                        <v-skeleton-loader
                          v-if="pageLoading"
                          class="custom-skeleton"
                          type="text"
                        ></v-skeleton-loader>
                        <template v-else>{{ projectCreate.barcode }}</template>
                      </td>
                      <td v-if="!isUpdateMode" class="font-size-16 pb-0 pt-0">
                        <v-btn
                          text
                          small
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          class="mx-2 custom-bold-button"
                          v-on:click="barcodeDialog = true"
                          color="cyan"
                        >
                          Change
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td width="200">
                        <label class="font-size-16 pb-0 required"
                          >Start Date
                        </label>
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Start Date *"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <DatePicker :pageLoading="pageLoading" solo
                        :placeholder="'Start Date *'" :default-date="
                        projectCreate.started_at "
                        v-model="projectCreate.started_at" mandatory: true />
                      </td>
                    </tr>
                    <tr>
                      <td width="200">
                        <label class="font-size-16 pb-0 required"
                          >End Date</label
                        >
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="End Date"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <DatePicker :pageLoading="pageLoading" solo
                        :placeholder="'End Date'" :default-date="
                        projectCreate.deadline "
                        :min-date="projectCreate.started_at"
                        v-model="projectCreate.deadline" mandatory: true />
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0" width="200">
                        Customer Purchase Order
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Customer Purchase Order"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          dense
                          color="cyan"
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model.trim="projectCreate.customer_purchase_order"
                          label="Customer Purchase Order"
                          :rules="[
                            validateRules.minLength(
                              projectCreate.customer_purchase_order,
                              'Customer Purchase Order',
                              1
                            ),
                            validateRules.maxLength(
                              projectCreate.customer_purchase_order,
                              'ReferenCustomer Purchase Orderce',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr>

                    <tr>
                      <td class="font-size-16 pb-0" width="200">Reference #</td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Reference #"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          dense
                          color="cyan"
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model.trim="projectCreate.reference"
                          label="Reference #"
                          :rules="[
                            validateRules.minLength(
                              projectCreate.reference,
                              'Reference',
                              1
                            ),
                            validateRules.maxLength(
                              projectCreate.reference,
                              'Reference',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr>

                    <tr>
                      <td class="font-size-16 pb-2" width="200">
                        Project Value
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Project Value"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          dense
                          color="cyan"
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          type="number"
                          v-on:keypress="
                            limitDecimal($event, projectCreate.project_value)
                          "
                          v-on:keyup="projectValue"
                          v-model="projectCreate.project_value"
                          v-model.trim.number.lazy="projectCreate.project_value"
                          label="Project Value"
                          :rules="[
                            validateRules.minLength(
                              projectCreate.project_value,
                              'Project Value',
                              1
                            ),
                            validateRules.maxLength(
                              projectCreate.project_value,
                              'Project Value',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr>

                    <tr>
                      <td class="font-size-16 pb-0" width="200">
                        Project Budget
                      </td>
                      <td
                        colspan="2"
                        v-on:keyup="projectValue"
                        class="pb-0"
                        content="Project Budget"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          dense
                          color="cyan"
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          type="number"
                          v-on:keypress="
                            limitDecimal($event, projectCreate.project_budget)
                          "
                          v-model="projectCreate.project_budget"
                          v-model.trim.number.lazy="
                            projectCreate.project_budget
                          "
                          label="Project Budget"
                          :rules="[
                            validateRules.minLength(
                              projectCreate.project_budget,
                              'Project Budget',
                              1
                            ),
                            validateRules.maxLength(
                              projectCreate.project_budget,
                              'Project Budget',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0" width="200">Managers</td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Managers"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          :items="managersList"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model.trim="projectCreate.managers"
                          label="Managers"
                          solo
                          flat
                          item-color="cyan"
                          item-text="full_name"
                          item-value="id"
                          hide-details
                          multiple
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.full_name }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text font-size-12"
                            >
                              (+{{ projectCreate.managers.length - 1 }} others)
                            </span>
                          </template>
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Member Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-size-16 pb-0" width="200">
                        Team Members
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Team Members"
                        valign="top"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          :items="memberList"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model.trim="projectCreate.members"
                          label="Members"
                          solo
                          flat
                          item-color="cyan"
                          item-text="full_name"
                          item-value="id"
                          hide-details
                          multiple
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.full_name }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text font-size-12"
                            >
                              (+{{ projectCreate.members.length - 1 }} others)
                            </span>
                          </template>
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Member Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </td>
                    </tr>
                    <!-- 
                    <tr>
                      <td class="font-size-16 pb-0" width="200">
                        Specification
                      </td>
                      <td
                        colspan="2"
                        class="pb-0"
                        content="Specification"
                        v-tippy="{ placement: 'top-start' }"
                      >
                        <v-text-field
                          dense
                          color="cyan"
                          filled
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          v-model.trim="projectCreate.specification"
                          label="Specification"
                          :rules="[
                            validateRules.minLength(
                              projectCreate.specification,
                              'Specification',
                              1
                            ),
                            validateRules.maxLength(
                              projectCreate.specification,
                              'Specification',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr> -->
                  </table>
                </v-container>
              </v-col>
              <v-col cols="12">
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius"
                  :disabled="pageLoading"
                >
                  <v-card-text class="p-6 font-size-16">
                    <v-row>
                      <v-col cols="6" class="pt-0 left-side-border" style="">
                        <h3
                          class="font-weight-700 custom-headline color-custom-blue"
                        >
                          Banker Guarantee
                        </h3>
                        <table width="100%">
                          <tr>
                            <td class="font-size-16 pb-0" width="200">
                              Bank Name
                            </td>
                            <td
                              class="pb-0"
                              content="Bank Name"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <v-autocomplete
                                dense
                                filled
                                color="cyan"
                                item-color="cyan"
                                :items="bankList"
                                label="Bank Name"
                                solo
                                flat
                                append-outer-icon="mdi-cog"
                                v-on:click:append-outer="
                                  manageBankNameDialog = true
                                "
                                item-text="text"
                                item-value="text"
                                class="pr-2 width-100"
                                v-model.trim="projectCreate.bank_name"
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Bank Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr>
                          <tr>
                            <td class="font-size-16 pb-0" width="200">
                              Amount
                            </td>
                            <td
                              colspan="2"
                              class="pb-0"
                              content="Amount"
                              valign="top"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <v-text-field
                                dense
                                color="cyan"
                                filled
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                type="number"
                                v-on:keypress="
                                  limitDecimal(
                                    $event,
                                    projectCreate.bank_amount
                                  )
                                "
                                v-model="projectCreate.bank_amount"
                                v-model.trim.number.lazy="
                                  projectCreate.bank_amount
                                "
                                label="Amount"
                                :rules="[
                                  validateRules.minLength(
                                    projectCreate.bank_amount,
                                    'Amount',
                                    1
                                  ),
                                  validateRules.maxLength(
                                    projectCreate.bank_amount,
                                    'Amount',
                                    100
                                  ),
                                ]"
                                solo
                                flat
                                hide-details
                              ></v-text-field>
                            </td>
                          </tr>

                          <tr>
                            <td
                              class="font-size-16 pb-0"
                              width="200"
                              valign="top"
                            >
                              Remark
                            </td>
                            <td
                              colspan="2"
                              class="pb-0"
                              content="Remark"
                              valign="top"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <v-textarea
                                v-model.trim="projectCreate.bank_remark"
                                auto-grow
                                dense
                                filled
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                color="cyan"
                                label="Remark"
                                solo
                                flat
                                v-on:keypress="
                                  (e) => manageLimit(e, 'bank_remark')
                                "
                                v-on:paste="(e) => onPaste(e, 'bank_remark')"
                                :rules="[
                                  validateRules.minLength(
                                    projectCreate.bank_remark,
                                    'Remark',
                                    1
                                  ),
                                  validateRules.maxLength(
                                    projectCreate.bank_remark,
                                    'Remark',
                                    1024
                                  ),
                                ]"
                                row-height="10"
                              ></v-textarea>
                              <div class="text-right">
                                {{
                                  projectCreate.bank_remark
                                    ? projectCreate.bank_remark.length
                                    : 0
                                }}/200
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <!--   <td class="font-size-16 pb-0" width="200" valign="top">
                                Documents/Attachment
                              </td> -->
                            <td
                              colspan="3"
                              class="pb-0"
                              content="Documents/Attachment"
                              valign="top"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <div class="text-center">
                                <FileTemplate
                                  :attachments="bank_attachments"
                                  v-on:file:updated="
                                    updateFiles('bank', $event)
                                  "
                                  allowUpload
                                ></FileTemplate>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                      <v-col cols="6" class="pt-0">
                        <h3
                          class="font-weight-700 custom-headline color-custom-blue"
                        >
                          Insurance Project
                        </h3>
                        <!-- <tr>
                          <td colspan="3" class="font-weight-700 font-size-16 pb-0">
                            Insurance Project
                          </td>
                        </tr> -->
                        <table width="100%">
                          <tr>
                            <td class="font-size-16 pb-0" width="200">
                              Insurance Company
                            </td>
                            <td
                              colspan="2"
                              class="pb-0"
                              content="Insurance Company"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                :items="insuranceList"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                v-model.trim="projectCreate.insurance_company"
                                label="Insurance Company"
                                solo
                                flat
                                item-color="cyan"
                                item-text="text"
                                item-value="text"
                                hide-details
                                append-outer-icon="mdi-cog"
                                v-on:click:append-outer="
                                  manageInsuranaceCompanyDialog = true
                                "
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Insurance Company Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </td>
                          </tr>

                          <tr>
                            <td class="font-size-16 pb-0" width="200">
                              Amount
                            </td>
                            <td
                              colspan="2"
                              class="pb-0"
                              content="Amount"
                              valign="top"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <v-text-field
                                dense
                                color="cyan"
                                filled
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                type="number"
                                v-on:keypress="
                                  limitDecimal(
                                    $event,
                                    projectCreate.insurance_amount
                                  )
                                "
                                v-model="projectCreate.insurance_amount"
                                v-model.trim.number.lazy="
                                  projectCreate.insurance_amount
                                "
                                label="Amount"
                                :rules="[
                                  validateRules.minLength(
                                    projectCreate.insurance_amount,
                                    'Amount',
                                    1
                                  ),
                                  validateRules.maxLength(
                                    projectCreate.insurance_amount,
                                    'Amount',
                                    100
                                  ),
                                ]"
                                solo
                                flat
                                hide-details
                              ></v-text-field>
                            </td>
                          </tr>

                          <tr>
                            <td
                              class="font-size-16 pb-0"
                              width="200"
                              valign="top"
                            >
                              Remark
                            </td>
                            <td
                              colspan="2"
                              class="pb-0"
                              content="Remark"
                              valign="top"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <v-textarea
                                v-model.trim="projectCreate.insurance_remark"
                                auto-grow
                                dense
                                filled
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                color="cyan"
                                label="Remark"
                                solo
                                flat
                                v-on:keypress="
                                  (e) => manageLimit(e, 'insurance_remark')
                                "
                                v-on:paste="
                                  (e) => onPaste(e, 'insurance_remark')
                                "
                                :rules="[
                                  validateRules.minLength(
                                    projectCreate.insurance_remark,
                                    'Remark',
                                    1
                                  ),
                                  validateRules.maxLength(
                                    projectCreate.insurance_remark,
                                    'Remark',
                                    1024
                                  ),
                                ]"
                                row-height="10"
                              ></v-textarea>
                              <div class="text-right">
                                {{
                                  projectCreate.insurance_remark
                                    ? projectCreate.insurance_remark.length
                                    : 0
                                }}/200
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <!--   <td class="font-size-16 pb-0" width="200" valign="top">
                            Documents/Attachment
                          </td> -->
                            <td
                              colspan="3"
                              class="pb-0"
                              content="Documents/Attachment"
                              valign="top"
                              v-tippy="{ placement: 'top-start' }"
                            >
                              <div class="text-center">
                                <FileTemplate
                                  :attachments="insurance_attachments"
                                  v-on:file:updated="
                                    updateFiles('insurance', $event)
                                  "
                                  allowUpload
                                ></FileTemplate>
                              </div>
                            </td>
                          </tr>
                        </table>
                        <!-- <editor v-model="notesAttachment.admin_notes" /> -->
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <div class="mt-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    Quotation Attachments
                  </h3>
                  <div class="text-center">
                    <FileTemplate
                      :attachments="quotation_attachments"
                      v-on:file:updated="updateFiles('quotation', $event)"
                      allowUpload
                    ></FileTemplate>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="mt-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    Attachments
                  </h3>
                  <div class="text-center">
                    <FileTemplate
                      :attachments="project_attachments"
                      v-on:file:updated="updateFiles('project', $event)"
                      allowUpload
                    ></FileTemplate>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar>
      </v-form>
      <BarcodeSetting
        endpoint="projects"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting="barcodeSetting"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
      <ManageBankName
        project
        :c-dialog="manageBankNameDialog"
        :bank="bankList"
        v-on:close-dialog="manageBankNameDialog = false"
        v-on:get-bank-name="getOptions"
      ></ManageBankName>

      <ManageInsuranceCompany
        project
        :c-dialog="manageInsuranaceCompanyDialog"
        :insurance="insuranceList"
        v-on:close-dialog="manageInsuranaceCompanyDialog = false"
        v-on:get-insurance-company="getOptions"
      ></ManageInsuranceCompany>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ManageBankName from "@/view/pages/partials/Manage-Bank-Name.vue";
import ManageInsuranceCompany from "@/view/pages/partials/Manage-Insurance-Company.vue";
import {
  PUT,
  GET,
  POST,
  QUERY,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
/* import TinyMCE from "@/view/pages/partials/TinyMCE.vue"; */
//import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "project-create",
  title: "Create Project",
  data() {
    return {
      manageBankNameDialog: false,
      manageInsuranaceCompanyDialog: false,
      duplicate: 0,
      pageLoading: true,
      isUpdateMode: false,
      bankList: [],
      insuranceList: [],
      barcodeDialog: false,
      isDuplicateMode: false,
      changeAttention: false,
      billing_contact_person: new Object(),
      customerObject: new Object(),
      project: 0,
      project_attachments: [],
      property: new Object(),
      property_contact_person: new Object(),
      bank_attachments: [],
      insurance_attachments: [],
      quotation_attachments: [],
      projectCreate: new Object({
        name: null,
        description: null,
        project_attachments: [],
        barcode: null,
        started_at: moment().format("YYYY-MM-DD"),
        deadline: moment().add(1, "month").format("YYYY-MM-DD"),
        status: 0,
        customer_purchase_order: null,
        reference: null,
        project_value: null,
        project_budget: null,
        members: [],
        managers: [],
        specification: null,
        bank_name: null,
        bank_amount: null,
        bank_remark: null,
        bank_attachments: [],
        quotation_attachments: [],
        insurance_company: null,
        insurance_amount: null,
        insurance_remark: null,
        insurance_attachments: [],
        customer: null,
        property: null,
        billing: null,
        property_contact_person: null,
        billing_contact_person: null,
        /* quotation: null,
        attention: null,
        soled_job_no: null,
        billing_type: 1,
        rate_per_hour: null,
        estimated_hour: null, */
      }),
      contactPersonObject: new Object(),
      barcodeSetting: new Object(),
      customer: null,
      projectProperty: null,
      memberList: [],
      managersList: [],
      billing: null,
      billingTypeList: [
        { text: "Fixed Rate", value: 1 },
        { text: "Project Hours", value: 2 },
      ],
    };
  },
  components: {
    CreateUpdateTemplate,
    DatePicker,
    BarcodeSetting,
    /*  editor: TinyMCE, */
    FileTemplate,
    ManageBankName,
    ManageInsuranceCompany,
  },
  watch: {
    formValid(param) {
      if (param) {
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },
    duplicate(param) {
      if (param > 0) {
        this.getProject(param);
      }
    },
    project(param) {
      if (param > 0) {
        this.getProject(param);
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Project", route: "project" },
          { title: "update" },
        ]);
      }
    },
  },
  methods: {
    projectValue() {
      if (
        this.projectCreate.project_budget >
        (this.projectCreate.project_value && this.projectCreate.project_value)
      ) {
        this.projectCreate.project_budget = this.projectCreate.project_value;
        return false;
      }
    },
    onPaste(e, type) {
      if (type == "project_description") {
        let coppied = e.clipboardData.getData("Text");
        let preVal = this.projectCreate.description;
        let finalval = String(preVal) + String(coppied);
        if (Number(finalval.length) > 199) {
          let trimValue = finalval.substring(0, 200);
          this.projectCreate.description = trimValue;
          e.preventDefault();
        }
      }
      if (type == "bank_remark") {
        let coppied = e.clipboardData.getData("Text");
        let preVal = this.projectCreate.bank_remark;
        let finalval = String(preVal) + String(coppied);
        if (Number(finalval.length) > 199) {
          let trimValue = finalval.substring(0, 200);
          this.projectCreate.bank_remark = trimValue;
          e.preventDefault();
        }
      }
      if (type == "insurance_remark") {
        let coppied = e.clipboardData.getData("Text");
        let preVal = this.projectCreate.insurance_remark;
        let finalval = String(preVal) + String(coppied);
        if (Number(finalval.length) > 199) {
          let trimValue = finalval.substring(0, 200);
          this.projectCreate.insurance_remark = trimValue;
          e.preventDefault();
        }
      }
    },

    manageLimit(e, type) {
      if (
        this.projectCreate.description &&
        this.projectCreate.description.length > 199 &&
        type == "project_description"
      ) {
        e.preventDefault();
      }
      if (
        this.projectCreate.bank_remark &&
        this.projectCreate.bank_remark.length > 199 &&
        type == "bank_remark"
      ) {
        e.preventDefault();
      }
      if (
        this.projectCreate.insurance_remark &&
        this.projectCreate.insurance_remark.length > 199 &&
        type == "insurance_remark"
      ) {
        e.preventDefault();
      }
    },
    updateFiles(type, param) {
      if (type == "project") {
        this.projectCreate.project_attachments = param;
      }

      if (type == "bank") {
        this.projectCreate.bank_attachments = param;
      }

      if (type == "insurance") {
        this.projectCreate.insurance_attachments = param;
      }

      if (type == "quotation") {
        this.projectCreate.quotation_attachments = param;
      }
    },

    getProject(projectId) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "projects/" + projectId,
        })
        .then(({ data }) => {
          _this.projectCreate.project = data.project;
          _this.projectCreate.barcode = data.barcode;
          _this.projectCreate.name = data.name;
          _this.projectCreate.description = data.description;

          _this.projectCreate.customer =
            data && data.customer && data.customer.id;
          _this.projectCreate.property =
            data && data.property && data.property.id;
          _this.projectCreate.billing = data && data.billing && data.billing.id;
          _this.projectCreate.customer_purchase_order =
            data.customer_purchase_order;
          _this.projectCreate.reference = data.reference;
          _this.projectCreate.project_value = data.project_value;
          _this.projectCreate.project_budget = data.project_budget;

          _this.projectCreate.specification = data.specification;
          _this.projectCreate.bank_name = data.bank_name;
          _this.projectCreate.bank_amount = data.bank_amount;
          _this.projectCreate.bank_remark = data.bank_remark;

          _this.projectCreate.insurance_company = data.insurance_company;
          _this.projectCreate.insurance_amount = data.insurance_amount;
          _this.projectCreate.insurance_remark = data.insurance_remark;

          _this.projectCreate.attention = data.attention;
          _this.projectCreate.reference = data.reference;
          _this.projectCreate.billing_type = data.billing_type;
          _this.projectCreate.rate_per_hour = data.rate_per_hour || null;
          _this.projectCreate.estimated_hour = data.estimated_hour || null;
          _this.projectCreate.started_at = data.started_at;
          _this.projectCreate.deadline = data.deadline;
          _this.projectCreate.status = data.status;

          if (data.members && data.members.length > 0) {
            _this.projectCreate.members = data.members.map((row) => {
              return row.user.id;
            });
          }
          if (data?.managers && data.managers?.length > 0) {
            _this.projectCreate.managers = data.managers.map((row) => {
              return row.user.id;
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    setStartDate(param) {
      this.projectCreate.started_at = param;
    },
    setDeadline(param) {
      this.projectCreate.deadline = param;
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "project/options",
          data: { customer: _this.customer, property: _this.projectProperty },
        })
        .then(({ data }) => {
          if (!_this.project) {
            _this.projectCreate.barcode = data.barcode;
          }
          _this.barcodeSetting = data.option;
          _this.contactPersonObject = data.contact_person;
          _this.projectCreate.attention = data.contact_person.display_name;
          _this.customerObject = data.customer;
          _this.memberList = data.members;
          _this.managersList = data?.managers;
          _this.insuranceList = data.insurance;
          _this.bankList = data.banks;

          if (!_this.lodash.isEmpty(data.billing)) {
            _this.billing = data.billing;
            _this.projectBilling = _this.billing.id;
            _this.projectCreate.billing = _this.billing.id;
          }

          if (!_this.lodash.isEmpty(data.property)) {
            _this.property = data.property;
            _this.projectProperty = _this.property.id;
            _this.projectCreate.property = _this.property.id;
          }

          if (!_this.lodash.isEmpty(data.property_person)) {
            _this.property_contact_person = data.property_person;
            _this.projectPropertyPerson = _this.property_contact_person.id;
            _this.projectCreate.property_contact_person =
              _this.property_contact_person.id;
          }

          if (!_this.lodash.isEmpty(data.billing_person)) {
            _this.billing_contact_person = data.billing_person;
            _this.projectBillingPerson = _this.billing_contact_person.id;
            _this.projectCreate.billing_contact_person =
              _this.property_contact_person.id;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    onSubmit() {
      const _this = this;
      try {
        if (!_this.$refs.projectForm.validate()) {
          return false;
        }

        _this.$store.dispatch(CLEAR_ERROR, {});

        _this.formLoading = true;

        let REQUEST_TYPE = POST;
        let REQUEST_URL = "projects";

        if (_this.project && _this.project > 0) {
          REQUEST_TYPE = PUT;
          REQUEST_URL = "projects/" + _this.project;
        }

        _this.$store
          .dispatch(REQUEST_TYPE, {
            url: REQUEST_URL,
            data: _this.projectCreate,
          })
          .then(({ data }) => {
            _this.$router.push(
              _this.getDefaultRoute("project.detail", {
                params: { id: data.id },
              })
            );
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    getQuotation() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "quotation/" + _this.quotation })
        .then(({ data }) => {
          _this.projectCreate.quotation = data.id;
          _this.projectCreate.name = data.job_title;
          _this.projectCreate.attention = data.attention;
          _this.projectCreate.reference = data.barcode;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  created() {
    const _this = this;
    _this.project = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicate = _this.lodash.toSafeInteger(_this.$route.query.duplicate);
    _this.customer = _this.lodash.toSafeInteger(_this.$route.query.customer);
    _this.projectProperty = _this.lodash.toSafeInteger(
      _this.$route.query.property
    );

    _this.quotation = _this.lodash.toSafeInteger(_this.$route.query.quotation);
    if (_this.quotation > 0) {
      _this.getQuotation();
    }
    _this.projectCreate.customer = _this.customer;
    if (_this.customer <= 0) {
      _this.$router.go(-1);
    }
  },
  mounted() {
    const _this = this;
    _this.getOptions();

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Project", route: "project" },
      { title: "Create" },
    ]);

    /* _this.$nextTick(() => {
      _this.$refs.projectForm.validate();
    }); */

    this.$nextTick(() => {
      // Access $refs.projectForm here
      //console.log(this.$refs.projectForm);
      // Perform any necessary operations
    });
  },
  computed: {
    billingTypeLabel() {
      if (this.projectCreate.billing_type == 1) {
        return "Total Rate";
      } else if (this.projectCreate.billing_type == 2) {
        return "Rate Per Hour";
      }
      return null;
    },
    defaultDeadline() {
      let today = new Date();
      today.setDate(today.getDate() + 30);
      return today.toISOString().substr(0, 10);
    },
  },
};
</script>
